import 'slick-carousel';

(function($){

    function gnav(){
        $('#gheader .gnavBtn').click(function(){
            $(this).toggleClass('is_active');
            $('#gnav').toggleClass('is_active');
            $('#gheader').toggleClass('is_fixed');
        });
    }
    function seachNav(){
        $('#gheader .search a').click(function(){
            $('#seachNav').addClass('is_active');
            return false;
        })
        $('#seachNav .navClose').click(function(){
            $('#seachNav').removeClass('is_active is_subShow');
        });
        $('#seachNav .subLinkCol .closeBtn').click(function(){
            $('#seachNav').removeClass('is_subShow');
        });
        var catLabel,listHTML;
        $('#seachNav .parentLink').click(function(){
            catLabel = $(this).text();
            listHTML = $(this).next(".subList").html();
            $('#seachNav .subLinkCol .catLabel').text(catLabel);
            $("#seachNav .subLinkCol .linkList").html(listHTML);
            $('#seachNav').addClass('is_subShow');
            return false;
        });
    }

    function mainSlides(){
        if($('.m_mvSlides').length){
            $('.m_mvSlides .slides').slick({
                autoplay: true,
                autoplaySpeed: 4000,
                arrows: false,
                dots: true,
            });
        }
    }

    function tab(){
        $('.js_tab .tabMenu > li a').click(function(){
            var targetTab = $(this).attr('href');
            $('.js_tab .tabMenu > li a').removeClass('is_active');
            $(this).addClass('is_active');
            $(this).closest('.js_tab').find('.tab').hide();
            $(targetTab).fadeIn();
            return false;
        });
    }

    function truncatedText(){
        $('.js_truncated').each(function() {
            var maxLength = $(this).data('maxlength');
            var text = $(this).text();
            if (text.length > maxLength) {
              var truncatedText = text.substring(0, maxLength) + '...';
              $(this).text(truncatedText);
            }
        });
    }

    $(function(){
        gnav();
        seachNav();
        mainSlides();
        tab();
        truncatedText();
    });

})(jQuery);
